.modal {
  display: flex;
  justify-content: center;
}

.delete-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 36px;
}

.delete-modal-content .fya-logo {
  height: 125px;
  width: 125px;
  margin-bottom: 20px;
}

.delete-modal-content .step-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-modal-content .step-text {
  text-align: center;
  margin-bottom: 40px;
}

.delete-modal-content .step-button-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .modal-heading {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .fya-logo {
    display: none;
  }

  .step-button-group {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .delete-modal-content .step-button-group {
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .delete-modal-content .step-text {
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    top: 60px;
    left: 20px;
  }

  .modal-content {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .modal-button-section {
    width: 80%;
    position: fixed;
    bottom: 75px;
  }

  .modal-button {
    width: 100% !important;
  }

  .modal-header {
    margin-top: 15px;
  }

  .check-icon {
    height: 175px;
    width: 175px;
  }
}
