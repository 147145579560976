.admin-dashboard-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.side-bar-and-map-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.close-button {
  float: right;
  color: var(--color-warning);
}

.marker-info-slide-admin,
.box-approval-slide-admin {
  width: min(100%, 500px) !important;
  z-index: 1300;
  background-color: #eef6f8;
  height: calc(100% - 57px);
  overflow-y: hidden;
  margin-top: 56px;
}

.marker-info-slide-general,
.box-approval-slide-general {
  width: min(100%, 500px) !important;
  z-index: 1300;
  background-color: #eef6f8;
  height: calc(100% - 57px - 54px);
  overflow-y: hidden;
  margin-top: 57px;
}

.map {
  width: 100%;
}

.admin-map {
  height: calc(100vh - 58.66px);
}

.general-user-map {
  height: calc(100vh - 55px - 57px);
}

.review-submission-button {
  position: absolute !important;
  left: 10px;
  z-index: 400; /* since leaflet has a high z index */
  visibility: visible;
  color: var(--color-white) !important;
}

.admin-button {
  bottom: 10px;
}

.general-user-button {
  bottom: 70px;
}

.review-submission-button.show-review {
  visibility: hidden;
}

.review-submission-button:hover .side-bar {
  display: block;
}

.close-button {
  float: right;
}

.footer {
  z-index: 999;
}

@media screen and (max-width: 768px) {
  .marker-info-slide-admin,
  .box-approval-slide-admin,
  .marker-info-slide-general,
  .box-approval-slide-general {
    width: 100vw !important;
  }
}
