.dropzone-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: 280px;
}

.dropzone-zone {
  padding: 55px;
  border-radius: 5px;
  border-width: 2px;
  border-style: dashed;
  border-color: #b9b9b9;
  color: #b9b9b9;
}

.dropzone-text {
  color: black;
  text-align: center;
}

.dropzone-support-text {
  font-size: 0.8rem;
}

.files-list {
  list-style-type: none;
  padding: 0;
}

.file-item-span {
  color: var(--text-gray-color);
  margin-right: 10px;
}

.dropzone-icon {
  width: 80px;
  height: 70px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .dropzone-content {
    width: 100%;
    height: 80%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
