.about-page {
  padding: 75px 100px;
  font-size: 1.25rem;
}

.about-heading {
  font-weight: 700;
  text-decoration: underline;
}

.about-title-heading {
  font-weight: 700;
}

.welcome-graphics-container-mobile {
  display: none;
}

.welcome-graphics-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 75px 100px 75px 0px;
}

.welcome-graphics-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 75%;
}

.welcome-graphics-icon {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.welcome-graphics-text {
  margin-top: 30px;
  width: 20%;
  text-align: center;
}

.site-description-section {
  padding: 100px 0;
}

.tab-heading {
  padding: 0 5vw;
}

.launch-box-about-text-center {
  font-style: italic;
}

.privacy-note-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--color-lightest-blue);
  border-radius: 15px;
  padding: 35px 35px;
  margin-top: 75px;
}

.privacy-note-text {
  overflow-wrap: break-word;
}

.mobile-line-break {
  display: none;
}

.newline-mobile {
  display: none;
}

.hyperlink {
  color: var(--color-hyperlink);
}

.tabs {
  color: var(--color-gray);
}

.user-guide-section {
  display: flex;
  justify-content: space-between;
}

.launch-box-section,
.found-box-section {
  width: 45%;
}

.waves-icon {
  margin-left: -45px;
  width: 300px;
}

@media screen and (max-width: 768px) {
  .about-page {
    padding: 25px;
    font-size: 1.15rem;
  }

  .about-heading {
    font-size: 1.25rem !important;
  }

  .about-text {
    margin-top: 20px;
  }

  .about-text-center {
    padding: 35px 0;
  }

  .welcome-graphics-container {
    display: none;
  }

  .welcome-graphics-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 10px;
    margin-bottom: 35px;
  }

  .welcome-graphics-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 35px;
  }

  .welcome-graphics-icon {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .welcome-graphics-text {
    margin-top: 0;
    margin-left: 25px;
    width: 100%;
    text-align: left;
  }

  .site-description-section {
    padding: 50px 0;
  }

  .mobile-line-break {
    display: block;
  }

  .newline-mobile {
    display: block;
  }

  .user-guide-section {
    display: flex;
    flex-direction: column;
  }

  .launch-box-section,
  .found-box-section {
    width: 100%;
    padding: 0;
  }

  .privacy-note-section {
    margin: 0;
  }
}
