.check-box {
  background-color: var(--color-white);
  transform: scale(1.2);
  position: absolute;
  left: 30px;
}

.edit-row input {
  width: 200px;
  height: 35px;
  background-color: var(--color-white);
}

.edit-row .input-right-warning {
  color: var(--color-warning);
  position: absolute;
  top: -3px;
  right: 25px;
}

.check-icon {
  color: var(--color-gray);
  width: 20px;
  height: 15px;
}

.date-picker {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.date-picker-error {
  border: 2.2px solid var(--color-warning);
}

.csv-error td {
  border-top: 1px solid var(--color-warning);
  border-bottom: 1px solid var(--color-warning);
}

.csv-error td:last-child {
  border-right: 1px solid var(--color-warning);
}

.csv-error td:first-child {
  border-left: 1px solid var(--color-warning);
}
