.csv-form-labels {
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

@media screen and (max-width: 768px) {
  .csv-form-labels {
    font-size: 20px;
    font-weight: 500;
    display: flex;
  }

  .header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .header-selection-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .item-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
