:root {
  --text-gray-color: var(--color-gray);
}

* {
  box-sizing: border-box;
}

.relocate-box-form {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

.required-field::after {
  content: ' *';
  color: var(--color-warning);
}

/* box image section */
.box-image {
  width: 100%;
  height: 100%;
}

.box-image img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 10px;
}

.box-bottom {
  margin-bottom: 5%;
}

.box-bottom small {
  color: var(--text-gray-color);
}

.submit-button {
  float: right;
  margin-top: 3%;
}

.relocate-box-photo-preview-section {
  height: 50%;
}

.verification-sub-label {
  margin-bottom: 12px;
  color: var(--text-gray-color);
  font-style: italic;
  font-size: 14px;
}

.relocate-box-photo-preview-section-hidden {
  display: none;
}

.relocate-box-info-section-left,
.relocate-box-info-section-right {
  width: 40%;
}

.privacy-statement {
  margin-left: 3%;
  padding-bottom: 5%;
  font-size: 14px;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .relocate-box-form {
    flex-direction: column;
    align-items: center;
  }

  .relocate-box-info-section-left,
  .relocate-box-info-section-right {
    width: 80%;
  }

  .submit-button {
    width: 100% !important;
  }
}
