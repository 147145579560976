.forgot-password-form-container {
  width: 100%;
}

.forgot-password-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.send-email-button {
  align-self: flex-end;
}

.action-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.return-to-login-link {
  color: var(--color-hyperlink);
  text-decoration: underline;
  margin-top: 5%;
  align-self: center;
  display: none;
}

.return-to-login-button {
  align-self: flex-start;
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-teal);
  box-sizing: border-box;
  border-radius: 6px;
}

.form-heading {
  padding-bottom: 5%;
}

.info-text {
  padding-bottom: 5%;
}

@media screen and (max-width: 768px) {
  .return-to-login-button {
    display: none !important;
  }

  .return-to-login-link {
    display: block;
  }

  .send-email-button {
    width: 100% !important;
  }
}
