.wrapper {
  display: flex;
  padding: 10px 0;
}

.text-wrapper {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
}

.box-location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
