.box-approval {
  width: 100%;
  padding: 1%;
  background-color: var(--color-light-blue);
  position: relative;
  display: flex;
  flex-direction: column;
}
.tab-wrapper {
  flex: 9;
  flex-direction: column;
  width: 100%;
}

.box-list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(80vh - 60px);
  width: 100%;
}
.tab-panel {
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
}
.legend {
  background-color: var(--color-light-blue);
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.legend-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.request-changes-row,
.relocate-box-row,
.pickup-box-row {
  display: flex;
  flex: 1;
  width: 100%;
}
.request-changes-row-content,
.relocate-box-row-content,
.pickup-box-row-content {
  width: 100%;
}
.request-changes-row {
  align-items: center;
  margin: 1% 1% 1% 1%;
}

.relocate-box-row {
  margin: 1% 1% 1% 1%;
}

.pickup-box-row {
  margin: 1% 1% 1% 1%;
}

.request-changes-icon {
  width: 25px;
  float: left;
  margin-right: 4%;
  color: var(--color-request-changes);
}

.relocate-box-icon {
  width: 25px;
  float: left;
  margin-right: 4%;
}

.pickup-box-icon {
  width: 25px;
  float: left;
  margin-right: 4%;
}

.box-list-and-pagination {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .box-approval {
    padding: 1%;
    height: 100vh;
    background-color: var(--color-light-blue);
    position: relative;
  }

  .legend {
    display: flex;
    background-color: var(--color-light-blue);
    width: 100%;
    margin-left: 0;
    font-size: 12px;
    padding: 3% 0%;
    height: 5vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .pickup-box-text,
  .relocate-box-text,
  .request-changes-text {
    white-space: nowrap;
    text-align: center;
  }

  .box-list-and-pagination {
    height: calc(100vh - 140px);
  }
}
