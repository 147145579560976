.box {
  padding: 0px 15px 0px 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: var(--color-white);
}

.picture-div {
  height: 100%;
}

.accordion-button {
  box-shadow: none !important;
}

.picture-label {
  width: 100%;
  margin-left: 5%;
}

.form-label {
  margin-top: 5%;
}

.status-message-approved {
  align-self: center;
  color: var(--color-success);
  width: 90%;
}

.status-message-pending {
  color: var(--color-pending-changes);
  width: 90%;
}

.status-message-rejected {
  width: 90%;
  color: var(--color-warning);
}

.image-wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  border: 1px dashed var(--color-black);
  opacity: 0.4;
}

.image-box {
  margin: 25% 40% 25% 40%;
}

.accordion-panel {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 10px;
}

.image-functionality,
.message-functionality {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.message-approved {
  border-color: var(--color-success) !important;
}

.message-rejected {
  border-color: var(--color-warning) !important;
}

.image-approved {
  border: 1px solid var(--color-success);
}

.image-rejected {
  border: 1px solid var(--color-warning);
}

.status-message {
  float: left;
  padding: 1%;
  width: 100%;
  margin-left: 1%;
}

.approval-message {
  color: var(--color-success);
  text-align: left;
}

.rejection-message {
  color: var(--color-warning);
  text-align: left;
}

.approval-button,
.rejection-button {
  float: left;
  margin: 0% 2% 0% 0%;
}

.image-functionality-wrapper,
.message-functionality-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2%;
}

.image-approved-button,
.message-approved-button {
  float: right;
  margin: 2% 0% 2% 2%;
}

.image-rejected-button,
.message-rejected-button {
  float: right;
  margin: 2% 1% 2% 2%;
}

.box-approved {
  background-color: var(--color-approved);
}

.box-rejected {
  background-color: var(--color-light-warning);
}

.box-pending {
  background-color: var(--color-light-pending-changes);
}

.arrowButton {
  float: right;
}

.box-number {
  font-weight: bold;
}
.box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.box-header-info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin: 15px 0px;
}

.title {
  min-height: 50px;
  float: left;
  padding-left: 20px;
  text-align: left;
}

.subtitle {
  margin-top: 3%;
  margin-bottom: 3%;
}

.icon-row {
  width: 100%;
  padding-top: 5%;
  float: bottom;
}

.close-icon {
  width: 33.33%;
  float: left;
}

.arrow-forward-icon {
  width: 33.33%;
  float: left;
}

.check-icon {
  width: 33.33%;
  float: right;
}

.title-div {
  min-width: 70px;
  float: left;
  float: top;
  width: 100%;
}

.box-header-info h3 {
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
}

.box-header-info h3,
.box-headerinfo p {
  margin: 0;
}

.box-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 90%;
}

.box-details p {
  margin: 0;
}

.box-header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 25%;
}

.image-corners {
  border-radius: 5%;
}

.request-changes-icon {
  color: var(--color-request-changes);
  font-size: 30px;
}

.reject-box-icon {
  color: var(--color-warning);
  font-size: 30px;
}

.approve-box-icon {
  color: var(--color-success);
  font-size: 30px;
}

.review-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit-box-form {
  width: 100% !important;
}
