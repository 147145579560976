@import url('https://fonts.googleapis.com/css2?family=Verdana&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Verdana', sans-serif !important;
  color: var(--color-text) !important;
  margin: 0;
  padding: 0;
}

input,
textarea,
button {
  font-family: inherit;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
}

p {
  font-size: 16px;
}
