.green-check-icon {
  color: green !important;
  width: 13px;
  height: 10px;
}

.red-cross-icon {
  color: red !important;
  width: 10px;
  height: 10px;
}

.green-check-icon,
.red-cross-icon {
  position: absolute;
  left: 80px;
  top: 40%;
}
