.login-form-container {
  width: 100%;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.login-button {
  width: 100% !important;
  align-self: flex-end;
  margin-top: 5%;
}

.form-heading {
  padding-bottom: 5%;
}

.forgot-password {
  color: var(--color-hyperlink);
  text-decoration: underline;
  margin-top: 5%;
  align-self: center;
  display: none;
}

@media screen and (max-width: 768px) {
  .forgot-password {
    display: block;
  }

  .login-button {
    margin-top: 0;
  }
}
