.csv-preview-wrapper {
  border: 2px solid var(--color-light-blue);
  width: 100%;
  margin: 1em 2em 0em 0em;
  padding: 0 20px;
  height: calc(100vh - 352px);
}

.scrollable-div {
  overflow-y: overlay;
  max-height: 50%;
}

.scrollable-div::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(78, 73, 73, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.csv-preview {
  border-collapse: separate !important;
  width: 100%;
  border-spacing: 0px 20px;
}

.csv-preview-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.csv-preview-title {
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: 600;
}

.csv-preview td,
.csv-preview th {
  text-align: left;
  max-width: 300px;
  position: relative;
  padding: 8px;
  height: 80px;
  background-color: var(--color-light-gray);
}

.csv-preview td:last-child,
.csv-preview th:last-child {
  gap: 20px;
  border-radius: 0 8px 8px 0;
}

.csv-preview td:first-child,
.csv-preview th:first-child {
  padding-left: 20px;
  border-radius: 8px 0 0 8px;
}

.csv-preview th {
  font-weight: 600;
  border-bottom: none;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: 0;
  justify-content: center;
  white-space: nowrap;
  color: black;
  justify-content: center;
  white-space: nowrap;
}

.csv-preview th:first-child {
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .scrollable-div {
    overflow-y: overlay;
    max-height: 65vh;
  }
}
