.custom-toast-wrapper {
  height: auto;
  z-index: 1000;
  width: 100%;
  color: white;
  border-radius: 6px;
  font-family: 'Verdana';
  font-style: normal;
  padding: 1%;
  font-family: var(--chakra-fonts-body);
}
.custom-toast-content {
  width: 100%;
  display: flex;
}
.custom-toast-message-header {
  font-size: 1vh;
}
.custom-toast-icon {
  width: 50px;
  display: flex;
  justify-content: center;
  padding: 1%;
}
.custom-toast-message {
  flex: 20;
  padding: 1%;
}
.custom-toast-exit {
  flex: 1;
  padding: 1%;
}
.custom-toast-exit-button:hover {
  background-color: transparent !important;
}
