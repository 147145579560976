.box {
  padding: 0px 15px 0px 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: var(--color-white);
}

.rejected-icon {
  color: var(--color-warning);
  font-size: 30px;
}

.approved-icon {
  color: var(--color-success);
  font-size: 30px;
}

.status-message-approved {
  align-self: center;
  color: var(--color-approved);
  width: 90%;
}

.status-message-pending {
  color: var(--color-pending-changes);
  width: 90%;
}

.status-message-rejected {
  width: 90%;
  color: var(--color-warning);
}

.image-wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  border: 1px dashed var(--color-black);
  opacity: 0.4;
}

.accordion-button {
  box-shadow: none !important;
}

.image-box {
  margin: 25% 20% 25% 20%;
}

.accordion-panel {
  background-color: var(--color-white);
  border-radius: 10px;
}

.arrow-forward-icon {
  width: 33.33%;
  float: left;
}

.box-approved {
  background-color: var(--color-approved);
  padding: 0px 15px 15px 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.box-rejected {
  background-color: var(--color-light-warning);
  padding: 0px 15px 15px 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.box-pending {
  background-color: var(--color-light-pending-changes);
  padding: 0px 15px 15px 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.arrow-button {
  float: right;
}

.box-number {
  font-weight: bold;
}

.box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.box-header-info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin: 15px 0px;
}

.form-label {
  margin-top: 5%;
}

.title {
  min-height: 50px;
  float: left;
  padding-left: 20px;
  text-align: left;
}

.subtitle {
  margin-top: 3%;
  margin-bottom: 3%;
}

.icon-row {
  width: 100%;
  float: bottom;
  padding-top: 5%;
}

.request-changes-icon {
  color: var(--color-request-changes);
  font-size: 30px;
}

.close-icon {
  width: 33.33%;
  float: left;
}

.check-icon {
  width: 33.33%;
  float: right;
}

.title-div {
  float: left;
  width: 100%;
}

.box-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  width: 90%;
}

.box-details p {
  margin: 0;
}

.image-corners {
  border-radius: 5%;
}

.image-functionality-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2%;
}

.image-functionality {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.approval-button,
.rejection-button {
  float: left;
  margin: 0% 2% 0% 0%;
}

.review-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.image-approved {
  border: 1px solid var(--color-success);
}

.image-rejected {
  border: 1px solid var(--color-warning);
}

.status-message {
  float: left;
  padding: 1%;
  width: 100%;
  margin-left: 1%;
}

.approval-message {
  text-align: left;
  color: var(--color-success);
}

.rejection-message {
  text-align: left;
  color: var(--color-warning);
}

.image-approved-button {
  float: right;
  margin: 2% 0% 2% 2%;
}

.image-rejected-button {
  float: right;
  margin: 2% 1% 2% 2%;
}
