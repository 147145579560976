@import './common/vars.css';

* {
  padding: 0;
  margin: 0;
}

/* Have to do this because admin map component is changed */
#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
