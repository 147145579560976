.header {
  display: flex;
  padding: 0 10% 0 5%;
}

.header-contents {
  margin-left: 20px;
  display: flex;
}

.box-info .close-button {
  float: right;
  color: var(--color-warning);
  width: 13px;
  cursor: pointer;
}

.box-info {
  width: 100%;
  background-color: var(--color-white);
  padding-bottom: 20px;
}

.back-button {
  cursor: pointer;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.box-data {
  width: 100%;
  padding: 0% 15% 0% 15%;
}

.back-button {
  float: left;
}

.form-label {
  margin-top: 5%;
}

.history-div {
  margin-top: 5%;
}

.image {
  border-radius: 10px;
  margin-top: 5%;
  width: 100%;
  padding: 0% 15% 0% 15%;
}

.box-info .box-number {
  font-weight: bold;
}

.button-div {
  bottom: 0;
  float: right;
  margin-top: 3%;
}

.history-graph {
  background-color: var(--color-white);
  width: 100%;
  margin-left: 10px;
}

.mobile-icon {
  display: block;
  height: 25px;
}

.icon-number-wrapper {
  display: flex;
}

.desktop-icon {
  height: 30px;
}

.mobile-delete-box-button {
  color: var(--color-warning);
  position: fixed;
  right: 2%;
  bottom: 0;
  width: 30px;
}

.desktop-delete-box-button {
  position: fixed !important;
  right: 20px;
  bottom: 5px;
}

@media screen and (max-width: 768px) {
  .header-contents {
    width: 100%;
    display: block;
    justify-content: space-between;
    margin-left: 0px;
  }

  .title {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .date {
    float: right;
    margin-left: 5%;
  }

  .box-number {
    float: right;
  }

  .box-data {
    width: 100%;
    padding: 0% 7% 0% 7%;
  }

  .icon-number-wrapper {
    width: 50%;
  }

  .image {
    border-radius: 0;
    width: 100%;
    padding: 0;
    margin-top: 1%;
  }
}
