.page-container {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  background-color: var(--color-teal);
}

.auth-form {
  flex: 1;
  background-color: white;
}

.auth-container {
  height: 100%;
  padding: 1% 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.banner {
  flex: 1;
  background-size: cover;
  background-position: center;
}

.logo-container {
  align-self: flex-start;
}

.auth-form-component {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-mobile {
  width: 50px;
  height: auto;
  display: none;
}

.logo-container-mobile {
  margin-top: 12.5px;
  margin-left: 12.5px;
  max-width: fit-content;
}

@media screen and (max-width: 768px) {
  .page-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .auth-form-component {
    width: 90%;
    margin: 20px 0px;
  }

  .logo-container,
  .logo {
    display: none;
  }

  .logo-mobile {
    display: block;
  }

  .auth-form {
    flex: 0;
  }
}
