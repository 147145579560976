.footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 0 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.5rem;
  height: 55px;
  z-index: 999;
}

.social-icon-text {
  display: block;
  text-decoration: underline;
  margin-right: auto;
}

.social-icon {
  width: 85%;
}

.social-icon-link {
  margin-left: 17.5px;
}

@media screen and (max-width: 768px) {
  .footer {
    font-size: 1rem;
    justify-content: space-between;
    padding: 0 50px;
  }

  .social-icon-text {
    display: none;
  }

  .social-icon {
    width: 70%;
  }

  .social-icon-link {
    margin: 0;
  }
}
