* {
  box-sizing: border-box;
}

.add-box-form {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

/* textarea section */
.text-area {
  resize: none;
}

/* launched organically section */
.box-launched-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.box-launched-section label {
  margin: 0;
}

.info-icon {
  margin-bottom: 2px;
  position: relative;
}

/* tooltip */
.info-icon:hover .tool-tip-text {
  visibility: visible;
}

.info-icon .tool-tip-text {
  visibility: hidden;
  /* tooltip styling */
  width: 200px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 5px;
  /* position the tooltip */
  font-size: 0.7rem;
  box-shadow: 0px 6px 10px var(--color-black);
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -105px;
}

.info-icon .tool-tip-text::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--color-black) transparent;
}

.info-icon {
  margin-left: 2%;
}

.submit-button {
  float: right;
  margin: 5% 0;
}

.required-field::after {
  content: ' *';
  color: var(--color-warning);
}

/* date picker */
.date-picker {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.date-picker-error {
  border: 2.5px solid var(--color-warning);
}

.box-image img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 5px;
}

.add-box-photo-preview-section {
  height: 50%;
}

.add-box-photo-preview-section-hidden {
  display: none;
}

.add-box-info-section-left,
.add-box-info-section-right {
  width: 40%;
}

.box-launch-label {
  display: flex;
  width: 50%;
}

.launch-radio label {
  margin-right: 80%;
}

.additional-comments-sub-label {
  color: var(--text-gray-color);
}

@media screen and (max-width: 768px) {
  .add-box-form {
    flex-direction: column;
    align-items: center;
  }

  .box-launched-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .add-box-info-section-left,
  .add-box-info-section-right {
    width: 80%;
  }

  .launch-radio label {
    margin-right: 100%;
  }

  .box-launch-label {
    width: 100%;
  }

  .attach-box-photo-label {
    margin-left: 10%;
  }

  .submit-button {
    margin-top: 0%;
    margin-bottom: 8%;
    width: 100% !important;
  }

  .dropzone {
    width: 80%;
    margin: auto;
  }

  .launch-org-message {
    margin-left: 10%;
  }
}
