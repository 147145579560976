.right-side-bar-box-list {
  width: 100%;
  height: 100%;
  background-color: var(--color-light-mint);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.right-side-bar-box-info {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.right-side-bar-box-list .close-button,
.right-side-bar-box-info .close-button {
  margin-right: 15px;
  margin-left: auto;
  color: var(--color-warning);
  width: 13px;
  cursor: pointer;
}
