.register-form-container {
  width: 100%;
  margin-top: 5%;
}

.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.register-button {
  width: 100% !important;
  align-self: flex-end;
}

.form-heading {
  padding-bottom: 5%;
}
