.edit-icon {
  color: var(--color-gray) !important;
  width: 15px;
  height: 15px;
}

.delete-icon {
  color: var(--color-gray) !important;
  width: 20px;
  height: 20px;
}

.green-check-icon {
  color: var(--color-success) !important;
  width: 13px;
  height: 10px;
}

.red-cross-icon {
  color: var(--color-warning) !important;
  width: 10px;
  height: 10px;
}

.green-check-icon,
.red-cross-icon {
  position: absolute;
  left: 70px;
  top: 40%;
}

.csv-error td {
  border-top: 1px solid var(--color-warning);
  border-bottom: 1px solid var(--color-warning);
}

.csv-error td:last-child {
  border-right: 1px solid var(--color-warning);
}

.csv-error td:first-child {
  border-left: 1px solid var(--color-warning);
}

.input-right-warning {
  color: var(--color-warning);
  position: absolute;
  top: -3px;
  right: 25px;
}

.date-picker {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.date-picker-error {
  border: 2.2px solid var(--color-warning);
}
