.error-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 36px 20px;
  margin-top: 100px;
}

.error-modal-buttons {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.error-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 15%;
}

.error-modal-file-name {
  color: var(--color-hyperlink);
}

.error-modal-red-text {
  color: var(--color-warning);
}
