.box-form-confirmation-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.confirmation-text,
.confirmation-message {
  margin-top: 3%;
  text-align: center;
  width: 60%;
}

.check-icon {
  margin: 0 auto;
  height: 125px;
  width: 125px;
}

.confirmation-check-circle {
  font-size: 125px;
}

.confirmation-button {
  margin-top: 5%;
}

@media screen and (max-width: 768px) {
  .confirmation-text {
    margin-top: 10%;
  }

  .confirmation-message {
    margin-left: 10%;
    margin-right: 10%;
  }

  .confirmation-button {
    position: absolute !important;
    bottom: 60px;
    width: 80% !important;
  }
}
