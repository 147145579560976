@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Verdana&display=swap);
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Verdana', sans-serif !important;
  color: var(--color-text) !important;
  margin: 0;
  padding: 0;
}

input,
textarea,
button {
  font-family: inherit;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
}

p {
  font-size: 16px;
}

:root {
  font-family: 'Verdana', sans-serif;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-gray: #8e8e8e;
  --color-light-gray: #e2e8f0;
  --color-off-white: #f2f2f2;
  --color-teal: #345e80;
  --color-light-mint: #d7eaed;
  --color-light-blue: #eef6f8;
  --color-mint: #89bfbc;
  --color-dark-blue: #1f2f38;
  --color-success: #38a169;
  --color-light-warning: #fed7d7;
  --color-warning: #e53e3e;
  --color-request-changes: #dd6b20;
  --color-approved: #c6f6d5;
  --color-light-pending-changes: #bee3f8;
  --color-pending-changes: #3182ce;
  --color-hyperlink: #3182ce;
  --color-text: #2d3748;
  --color-lightest-blue: #eef8f9;
}

* {
  padding: 0;
  margin: 0;
}

/* Have to do this because admin map component is changed */
#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ShowToast_custom-toast-wrapper__2Fr4x {
  height: auto;
  z-index: 1000;
  width: 100%;
  color: white;
  border-radius: 6px;
  font-family: 'Verdana';
  font-style: normal;
  padding: 1%;
  font-family: var(--chakra-fonts-body);
}
.ShowToast_custom-toast-content__1Rpj3 {
  width: 100%;
  display: flex;
}
.ShowToast_custom-toast-message-header__30ObJ {
  font-size: 1vh;
}
.ShowToast_custom-toast-icon__RuF0C {
  width: 50px;
  display: flex;
  justify-content: center;
  padding: 1%;
}
.ShowToast_custom-toast-message__3RgCX {
  flex: 20 1;
  padding: 1%;
}
.ShowToast_custom-toast-exit__UPEUg {
  flex: 1 1;
  padding: 1%;
}
.ShowToast_custom-toast-exit-button__2LcYb:hover {
  background-color: transparent !important;
}

/* Animation and styling for ellipses after the loading text. */
.LoadingPage_one__394mg {
  opacity: 0;
  -webkit-animation: LoadingPage_dot__1NUPU 1.3s infinite;
  -webkit-animation-delay: 0s;
  animation: LoadingPage_dot__1NUPU 1.3s infinite;
  animation-delay: 0s;
}

.LoadingPage_two__2XfbG {
  opacity: 0;
  -webkit-animation: LoadingPage_dot__1NUPU 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: LoadingPage_dot__1NUPU 1.3s infinite;
  animation-delay: 0.2s;
}

.LoadingPage_three__3P28X {
  opacity: 0;
  -webkit-animation: LoadingPage_dot__1NUPU 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: LoadingPage_dot__1NUPU 1.3s infinite;
  animation-delay: 0.3s;
}

@keyframes LoadingPage_dot__1NUPU {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.LoadingPage_loading-page__1TCYH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.LoadingPage_loading-page-outer__-jbjv {
  display: flex;
  align-items: center;
  height: 100%;
}

.LoadingPage_fya-logo__3Cx83 {
  width: 15%;
  margin-left: 42.5%;
}

@media screen and (max-width: 768px) {
  .LoadingPage_fya-logo__3Cx83 {
    width: 50%;
    margin-left: 25%;
  }
}

.Layout_layout__3PvPc {
  margin: 0 auto;

  flex-grow: 1;
  width: 100%;
}

.Layout_navbar__15dt9 {
  height: 55px;
  z-index: 100;
  background-color: white;
}
.Layout_wrapper__267cj {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.CSVDropZone_dropzone-content__XEhwa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: 280px;
}

.CSVDropZone_dropzone-zone__1xXpP {
  padding: 55px;
  border-radius: 5px;
  border-width: 2px;
  border-style: dashed;
  border-color: #b9b9b9;
  color: #b9b9b9;
}

.CSVDropZone_dropzone-text__1a8mL {
  color: black;
  text-align: center;
}

.CSVDropZone_dropzone-support-text__4314f {
  font-size: 0.8rem;
}

.CSVDropZone_files-list__3d1tI {
  list-style-type: none;
  padding: 0;
}

.CSVDropZone_file-item-span__1tQBx {
  color: var(--text-gray-color);
  margin-right: 10px;
}

.CSVDropZone_dropzone-icon__1dwvq {
  width: 80px;
  height: 70px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .CSVDropZone_dropzone-content__XEhwa {
    width: 100%;
    height: 80%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.UploadModalContent_upload-modal-content__1rPIA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 36px;
}

.UploadModalContent_upload-modal-title__19hkF {
  color: var(--color-black);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.UploadModalContent_upload-modal-button__prtEF {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .UploadModalContent_upload-modal-content__1rPIA {
    height: 700px;
  }

  .UploadModalContent_upload-modal-title__19hkF {
    margin-top: 30px;
  }

  .UploadModalContent_upload-modal-button__prtEF {
    padding: 10px;
  }

  .UploadModalContent_upload-modal-back-button__11hsx {
    position: relative;
    right: 48%;
  }
}

.SuccessModalContent_success-modal-content__1g60f {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 18%;
}

.ErrorModalContent_error-modal-content__nSVWM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 36px 20px;
  margin-top: 100px;
}

.ErrorModalContent_error-modal-buttons__3xntw {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 30px;
}

.ErrorModalContent_error-modal-container__2XyCJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  position: absolute;
  top: 15%;
}

.ErrorModalContent_error-modal-file-name__3181d {
  color: var(--color-hyperlink);
}

.ErrorModalContent_error-modal-red-text__2Bifi {
  color: var(--color-warning);
}

.UploadCSV_common-modal__18lh7 {
  position: relative;
}

.UploadCSV_loading-text__kPFXA {
  position: absolute;
  margin: auto;
  top: 45%;
  left: 45%;
}

.LogoutModal_modal__3tjbr {
  display: flex;
}

.LogoutModal_logout-modal-content__1rvNF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.LogoutModal_logout-modal-content__1rvNF .LogoutModal_fya-logo__3PnRD {
  height: 125px;
  width: 125px;
  margin-bottom: 20px;
}

.LogoutModal_logout-modal-content__1rvNF .LogoutModal_step-content__1KtuK {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LogoutModal_logout-modal-content__1rvNF .LogoutModal_step-text__3zoYw {
  text-align: center;
  margin-bottom: 40px;
}

.LogoutModal_logout-modal-content__1rvNF,
.LogoutModal_step-button-group__lxYwY {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .LogoutModal_modal-heading__13mAR {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .LogoutModal_fya-logo__3PnRD {
    display: none;
  }

  .LogoutModal_step-button-group__lxYwY {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .LogoutModal_logout-modal-content__1rvNF .LogoutModal_step-button-group__lxYwY {
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .LogoutModal_logout-modal-content__1rvNF .LogoutModal_step-text__3zoYw {
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    top: 75px;
    left: 20px;
  }
}

.NavBar_navbar__yNL3i {
  position: -webkit-sticky;
  position: sticky;
  z-index: 900;
  background-color: var(--color-white);
}

.NavBar_navbar-initials__3efzn {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #3182ce;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.NavBar_nav-link-unselected__3UvQm {
  text-decoration: none;
}

.NavBar_nav-link__uhN-z {
  margin: auto;
  font-size: 1em !important;
  font-family: var(--chakra-fonts-body) !important;
  text-align: justify;
}

.NavBar_nav-link__uhN-z:hover {
  text-decoration: none !important;
}

.NavBar_nav-box__33CQn {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 533.25px;
  flex-grow: 1;
  justify-content: right;
}
.NavBar_nav-modal__9RPd7 {
  border-radius: 0px !important;
}

.NavBar_nav-link-container-mobile__3ZXsb {
  margin-top: 25px;
}

.NavBar_fya-logo__37atw {
  width: 49px;
}

@media screen and (max-width: 768px) {
  .NavBar_nav-link__uhN-z {
    font-size: 1.5em !important;
  }
}

.Dashboard_admin-dashboard-container__1Dm2T {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Dashboard_side-bar-and-map-container__3s1jC {
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Dashboard_close-button__brCbP {
  float: right;
  color: var(--color-warning);
}

.Dashboard_marker-info-slide-admin__1vrdC,
.Dashboard_box-approval-slide-admin__15rDr {
  width: min(100%, 500px) !important;
  z-index: 1300;
  background-color: #eef6f8;
  height: calc(100% - 57px);
  overflow-y: hidden;
  margin-top: 56px;
}

.Dashboard_marker-info-slide-general__3MAx2,
.Dashboard_box-approval-slide-general__19XpT {
  width: min(100%, 500px) !important;
  z-index: 1300;
  background-color: #eef6f8;
  height: calc(100% - 57px - 54px);
  overflow-y: hidden;
  margin-top: 57px;
}

.Dashboard_map__9UJsQ {
  width: 100%;
}

.Dashboard_admin-map__3kpE2 {
  height: calc(100vh - 58.66px);
}

.Dashboard_general-user-map__2Ia0I {
  height: calc(100vh - 55px - 57px);
}

.Dashboard_review-submission-button__2ZbG9 {
  position: absolute !important;
  left: 10px;
  z-index: 400; /* since leaflet has a high z index */
  visibility: visible;
  color: var(--color-white) !important;
}

.Dashboard_admin-button__3Q025 {
  bottom: 10px;
}

.Dashboard_general-user-button__2ty2V {
  bottom: 70px;
}

.Dashboard_review-submission-button__2ZbG9.Dashboard_show-review__13HHG {
  visibility: hidden;
}

.Dashboard_review-submission-button__2ZbG9:hover .Dashboard_side-bar__3OKBI {
  display: block;
}

.Dashboard_close-button__brCbP {
  float: right;
}

.Dashboard_footer__3Bs0I {
  z-index: 999;
}

@media screen and (max-width: 768px) {
  .Dashboard_marker-info-slide-admin__1vrdC,
  .Dashboard_box-approval-slide-admin__15rDr,
  .Dashboard_marker-info-slide-general__3MAx2,
  .Dashboard_box-approval-slide-general__19XpT {
    width: 100vw !important;
  }
}

.map-wrapper {
  width: 100%;
  height: calc(100vh - 57px - 55px);
}

.map-wrapper-admin {
  width: 100%;
  height: calc(100vh - 57px);
}

/* Required for displaying the map properly */
.leaflet-container {
  position: absolute;
  width: inherit;
  height: inherit;
}
/* Customization of material in popup  */
.marker-popup {
  width: 100%;
  text-align: center;
}

.leaflet-control-geosearch form {
  display: block;
  min-width: 250px;
  left: 30.2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 2px 0px 0px 2px;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px 0px 0px 2px;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.leaflet-touch .leaflet-bar:first-of-type {
  z-index: 999;
}

.tooltip {
  background-color: var(--color-teal);
  color: var(--color-white);
  border: 0px;
}

.marker-cluster {
  color: var(--color-white);
  background-color: var(--color-teal);
  border: 1px solid var(--color-teal);
  border-radius: 50%;
  text-align: center;
}

.leaflet-tooltip-top:before {
  border-top-color: var(--color-teal) !important;
}

.leaflet-top {
  display: flex;
  width: 350px;
  justify-content: space-between;
}

.leaflet-control-geosearch .results.active {
  max-width: 200px;
}

@media screen and (max-width: 768px) {
  .leaflet-top {
    flex-direction: column;
    width: auto;
  }
}

.PickupBox_box__1lc7Z {
  padding: 0px 15px 0px 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: var(--color-white);
}

.PickupBox_rejected-icon__eO0_t {
  color: var(--color-warning);
  font-size: 30px;
}

.PickupBox_approved-icon__1ffSG {
  color: var(--color-success);
  font-size: 30px;
}

.PickupBox_status-message-approved__-6oY5 {
  align-self: center;
  color: var(--color-approved);
  width: 90%;
}

.PickupBox_status-message-pending__8gYCG {
  color: var(--color-pending-changes);
  width: 90%;
}

.PickupBox_status-message-rejected__2UV7b {
  width: 90%;
  color: var(--color-warning);
}

.PickupBox_image-wrapper__1SjVt {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  border: 1px dashed var(--color-black);
  opacity: 0.4;
}

.PickupBox_accordion-button__1U33D {
  box-shadow: none !important;
}

.PickupBox_image-box__1gMpU {
  margin: 25% 20% 25% 20%;
}

.PickupBox_accordion-panel__cx6xS {
  background-color: var(--color-white);
  border-radius: 10px;
}

.PickupBox_arrow-forward-icon__3HnPr {
  width: 33.33%;
  float: left;
}

.PickupBox_box-approved__obF1o {
  background-color: var(--color-approved);
  padding: 0px 15px 15px 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.PickupBox_box-rejected__3CKrJ {
  background-color: var(--color-light-warning);
  padding: 0px 15px 15px 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.PickupBox_box-pending__1-9DW {
  background-color: var(--color-light-pending-changes);
  padding: 0px 15px 15px 15px;
  margin-top: 15px;
  border-radius: 10px;
}

.PickupBox_arrow-button__1hIvB {
  float: right;
}

.PickupBox_box-number__17DfS {
  font-weight: bold;
}

.PickupBox_box-header__1qWJz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.PickupBox_box-header-info__RLLVq {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin: 15px 0px;
}

.PickupBox_form-label__7KsOX {
  margin-top: 5%;
}

.PickupBox_title__2t-VL {
  min-height: 50px;
  float: left;
  padding-left: 20px;
  text-align: left;
}

.PickupBox_subtitle__3TgLv {
  margin-top: 3%;
  margin-bottom: 3%;
}

.PickupBox_icon-row__NvbIO {
  width: 100%;
  float: bottom;
  padding-top: 5%;
}

.PickupBox_request-changes-icon__2zJfe {
  color: var(--color-request-changes);
  font-size: 30px;
}

.PickupBox_close-icon__W4ko4 {
  width: 33.33%;
  float: left;
}

.PickupBox_check-icon__3_lhN {
  width: 33.33%;
  float: right;
}

.PickupBox_title-div__3zneF {
  float: left;
  width: 100%;
}

.PickupBox_box-details__1v6Zo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  width: 90%;
}

.PickupBox_box-details__1v6Zo p {
  margin: 0;
}

.PickupBox_image-corners__1V58X {
  border-radius: 5%;
}

.PickupBox_image-functionality-wrapper__1yLc_ {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2%;
}

.PickupBox_image-functionality__3gDHN {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.PickupBox_approval-button__1kJxt,
.PickupBox_rejection-button__1Qma5 {
  float: left;
  margin: 0% 2% 0% 0%;
}

.PickupBox_review-header__hLUYq {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PickupBox_image-approved__1NHVN {
  border: 1px solid var(--color-success);
}

.PickupBox_image-rejected__1uv4J {
  border: 1px solid var(--color-warning);
}

.PickupBox_status-message__1uszg {
  float: left;
  padding: 1%;
  width: 100%;
  margin-left: 1%;
}

.PickupBox_approval-message__3yA4r {
  text-align: left;
  color: var(--color-success);
}

.PickupBox_rejection-message__33lZZ {
  text-align: left;
  color: var(--color-warning);
}

.PickupBox_image-approved-button__2vyGZ {
  float: right;
  margin: 2% 0% 2% 2%;
}

.PickupBox_image-rejected-button__21kOV {
  float: right;
  margin: 2% 1% 2% 2%;
}

.RejectBoxPopup_reject-box-popup__rvaLK {
  width: 100%;
  height: 100%;
}

.RejectBoxPopup_text-area__3E-vU {
  margin-top: 5px;
}

.RequestChangesPopup_request-changes-popup__3GVS8 {
  width: 100%;
  height: 100%;
}

.RequestChangesPopup_text-area__LEUs2 {
  margin-top: 5px;
}

.RelocationBox_box__1S3I9 {
  padding: 0px 15px 0px 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: var(--color-white);
}

.RelocationBox_picture-div__1lTbk {
  height: 100%;
}

.RelocationBox_accordion-button__2fw2d {
  box-shadow: none !important;
}

.RelocationBox_picture-label__3vaRD {
  width: 100%;
  margin-left: 5%;
}

.RelocationBox_form-label__3qxh4 {
  margin-top: 5%;
}

.RelocationBox_status-message-approved__seW4u {
  align-self: center;
  color: var(--color-success);
  width: 90%;
}

.RelocationBox_status-message-pending__q5J4U {
  color: var(--color-pending-changes);
  width: 90%;
}

.RelocationBox_status-message-rejected__37si6 {
  width: 90%;
  color: var(--color-warning);
}

.RelocationBox_image-wrapper__sxAnv {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  border: 1px dashed var(--color-black);
  opacity: 0.4;
}

.RelocationBox_image-box__d5D2v {
  margin: 25% 40% 25% 40%;
}

.RelocationBox_accordion-panel__1xO8j {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 10px;
}

.RelocationBox_image-functionality__2Ghpx,
.RelocationBox_message-functionality__sUHGM {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.RelocationBox_message-approved__2lpQy {
  border-color: var(--color-success) !important;
}

.RelocationBox_message-rejected__2icwi {
  border-color: var(--color-warning) !important;
}

.RelocationBox_image-approved__2215o {
  border: 1px solid var(--color-success);
}

.RelocationBox_image-rejected__1moe1 {
  border: 1px solid var(--color-warning);
}

.RelocationBox_status-message__3Rt0T {
  float: left;
  padding: 1%;
  width: 100%;
  margin-left: 1%;
}

.RelocationBox_approval-message__11LeQ {
  color: var(--color-success);
  text-align: left;
}

.RelocationBox_rejection-message__vchJ_ {
  color: var(--color-warning);
  text-align: left;
}

.RelocationBox_approval-button__66grx,
.RelocationBox_rejection-button__3-bEP {
  float: left;
  margin: 0% 2% 0% 0%;
}

.RelocationBox_image-functionality-wrapper__27YyC,
.RelocationBox_message-functionality-wrapper__3yVVj {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2%;
}

.RelocationBox_image-approved-button__2MOVL,
.RelocationBox_message-approved-button__1vHEf {
  float: right;
  margin: 2% 0% 2% 2%;
}

.RelocationBox_image-rejected-button__3kTmc,
.RelocationBox_message-rejected-button__286Pb {
  float: right;
  margin: 2% 1% 2% 2%;
}

.RelocationBox_box-approved__11s9y {
  background-color: var(--color-approved);
}

.RelocationBox_box-rejected__JE1q4 {
  background-color: var(--color-light-warning);
}

.RelocationBox_box-pending__RCof9 {
  background-color: var(--color-light-pending-changes);
}

.RelocationBox_arrowButton__1rI8M {
  float: right;
}

.RelocationBox_box-number__34iRD {
  font-weight: bold;
}
.RelocationBox_box-header__39v15 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.RelocationBox_box-header-info__2UIti {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin: 15px 0px;
}

.RelocationBox_title__3vKDk {
  min-height: 50px;
  float: left;
  padding-left: 20px;
  text-align: left;
}

.RelocationBox_subtitle__3Ob26 {
  margin-top: 3%;
  margin-bottom: 3%;
}

.RelocationBox_icon-row__29CUp {
  width: 100%;
  padding-top: 5%;
  float: bottom;
}

.RelocationBox_close-icon__2S310 {
  width: 33.33%;
  float: left;
}

.RelocationBox_arrow-forward-icon__2dt4g {
  width: 33.33%;
  float: left;
}

.RelocationBox_check-icon__1QLPl {
  width: 33.33%;
  float: right;
}

.RelocationBox_title-div__3Lda2 {
  min-width: 70px;
  float: left;
  float: top;
  width: 100%;
}

.RelocationBox_box-header-info__2UIti h3 {
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
}

.RelocationBox_box-header-info__2UIti h3,
.RelocationBox_box-headerinfo__ReouC p {
  margin: 0;
}

.RelocationBox_box-details__HwSxz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 90%;
}

.RelocationBox_box-details__HwSxz p {
  margin: 0;
}

.RelocationBox_box-header-buttons__x0MWt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 25%;
}

.RelocationBox_image-corners__1oIlh {
  border-radius: 5%;
}

.RelocationBox_request-changes-icon__3cJN4 {
  color: var(--color-request-changes);
  font-size: 30px;
}

.RelocationBox_reject-box-icon__QI5ue {
  color: var(--color-warning);
  font-size: 30px;
}

.RelocationBox_approve-box-icon__c7JtX {
  color: var(--color-success);
  font-size: 30px;
}

.RelocationBox_review-header__17Ad3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.RelocationBox_edit-box-form__k8ANs {
  width: 100% !important;
}

.BoxApproval_box-approval__3Wfx4 {
  width: 100%;
  padding: 1%;
  background-color: var(--color-light-blue);
  position: relative;
  display: flex;
  flex-direction: column;
}
.BoxApproval_tab-wrapper__2hgcj {
  flex: 9 1;
  flex-direction: column;
  width: 100%;
}

.BoxApproval_box-list__1wYVi {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(80vh - 60px);
  width: 100%;
}
.BoxApproval_tab-panel__3x6JX {
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  flex: 1 1;
  flex-direction: column;
}
.BoxApproval_legend__1O0IM {
  background-color: var(--color-light-blue);
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.BoxApproval_legend-row__2O-69 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.BoxApproval_request-changes-row__3GGXr,
.BoxApproval_relocate-box-row__23siT,
.BoxApproval_pickup-box-row__1pQkj {
  display: flex;
  flex: 1 1;
  width: 100%;
}
.BoxApproval_request-changes-row-content__3_8Yl,
.BoxApproval_relocate-box-row-content__3yFZf,
.BoxApproval_pickup-box-row-content__FBkap {
  width: 100%;
}
.BoxApproval_request-changes-row__3GGXr {
  align-items: center;
  margin: 1% 1% 1% 1%;
}

.BoxApproval_relocate-box-row__23siT {
  margin: 1% 1% 1% 1%;
}

.BoxApproval_pickup-box-row__1pQkj {
  margin: 1% 1% 1% 1%;
}

.BoxApproval_request-changes-icon__2V-O5 {
  width: 25px;
  float: left;
  margin-right: 4%;
  color: var(--color-request-changes);
}

.BoxApproval_relocate-box-icon__1YPN9 {
  width: 25px;
  float: left;
  margin-right: 4%;
}

.BoxApproval_pickup-box-icon__1p7ri {
  width: 25px;
  float: left;
  margin-right: 4%;
}

.BoxApproval_box-list-and-pagination__3iL-W {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .BoxApproval_box-approval__3Wfx4 {
    padding: 1%;
    height: 100vh;
    background-color: var(--color-light-blue);
    position: relative;
  }

  .BoxApproval_legend__1O0IM {
    display: flex;
    background-color: var(--color-light-blue);
    width: 100%;
    margin-left: 0;
    font-size: 12px;
    padding: 3% 0%;
    height: 5vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .BoxApproval_pickup-box-text__2_Fqz,
  .BoxApproval_relocate-box-text__19h1R,
  .BoxApproval_request-changes-text__2Xm9s {
    white-space: nowrap;
    text-align: center;
  }

  .BoxApproval_box-list-and-pagination__3iL-W {
    height: calc(100vh - 140px);
  }
}

.PaginationController_pagination-indicator__CCfJX {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 1% 0% 0% 0%;
}

.PaginationController_back-button__3YOYz,
.PaginationController_forward-button__1y5-k {
  margin: 0% 3% 0% 3%;
}

.PaginationController_middle-section__1nTwY {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PaginationController_left-section__paNIW {
  float: left;
}

.MarkerInfo_right-side-bar-box-list__1PSPX {
  width: 100%;
  height: 100%;
  background-color: var(--color-light-mint);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.MarkerInfo_right-side-bar-box-info__25bzf {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.MarkerInfo_right-side-bar-box-list__1PSPX .MarkerInfo_close-button__1ggrZ,
.MarkerInfo_right-side-bar-box-info__25bzf .MarkerInfo_close-button__1ggrZ {
  margin-right: 15px;
  margin-left: auto;
  color: var(--color-warning);
  width: 13px;
  cursor: pointer;
}

.BoxList_box-list__2MhGe {
  width: 100%;
  height: calc(90vh - 60px);
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BoxList_box-list-item__gdnJV {
  width: 80%;
  padding: 5px 15px 5px 15px;
  border-radius: 6px;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 4%;
}

.BoxList_box-list__2MhGe .BoxList_box-list-item__gdnJV .BoxList_box-number__L8vDs {
  font-weight: bold;
  white-space: nowrap;
}

.BoxList_title__1xsHy {
  font-weight: bold;
  padding: 2%;
  width: 100%;
  text-align: center;
}

.BoxList_box-list-item-text__2R_vC {
  display: flex;
  flex-direction: column;
  float: left;
}

.BoxList_box-icon__1YyVZ {
  height: 30px;
  margin-top: 3px;
  padding: 0% 15% 0% 7%;
}

.BoxList_right-icon__yiZBe {
  float: right;
}

.BoxList_box-list-item-wrapper__pS-Xt {
  display: flex;
  justify-content: space-between;
}

.BoxInfo_header__Bq1xf {
  display: flex;
  padding: 0 10% 0 5%;
}

.BoxInfo_header-contents__2ZpY2 {
  margin-left: 20px;
  display: flex;
}

.BoxInfo_box-info__2-ukl .BoxInfo_close-button__2Q5im {
  float: right;
  color: var(--color-warning);
  width: 13px;
  cursor: pointer;
}

.BoxInfo_box-info__2-ukl {
  width: 100%;
  background-color: var(--color-white);
  padding-bottom: 20px;
}

.BoxInfo_back-button__1r2-B {
  cursor: pointer;
}

.BoxInfo_title__1_nQt {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BoxInfo_box-data__3TAQ4 {
  width: 100%;
  padding: 0% 15% 0% 15%;
}

.BoxInfo_back-button__1r2-B {
  float: left;
}

.BoxInfo_form-label__3uEPm {
  margin-top: 5%;
}

.BoxInfo_history-div__5FKct {
  margin-top: 5%;
}

.BoxInfo_image__1VN_e {
  border-radius: 10px;
  margin-top: 5%;
  width: 100%;
  padding: 0% 15% 0% 15%;
}

.BoxInfo_box-info__2-ukl .BoxInfo_box-number__ALgdN {
  font-weight: bold;
}

.BoxInfo_button-div__lcaEc {
  bottom: 0;
  float: right;
  margin-top: 3%;
}

.BoxInfo_history-graph__2s7cV {
  background-color: var(--color-white);
  width: 100%;
  margin-left: 10px;
}

.BoxInfo_mobile-icon__KCWMB {
  display: block;
  height: 25px;
}

.BoxInfo_icon-number-wrapper__2AtNG {
  display: flex;
}

.BoxInfo_desktop-icon__2Ubkq {
  height: 30px;
}

.BoxInfo_mobile-delete-box-button__3hExJ {
  color: var(--color-warning);
  position: fixed;
  right: 2%;
  bottom: 0;
  width: 30px;
}

.BoxInfo_desktop-delete-box-button__3LL7U {
  position: fixed !important;
  right: 20px;
  bottom: 5px;
}

@media screen and (max-width: 768px) {
  .BoxInfo_header-contents__2ZpY2 {
    width: 100%;
    display: block;
    justify-content: space-between;
    margin-left: 0px;
  }

  .BoxInfo_title__1_nQt {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .BoxInfo_date__2BwDD {
    float: right;
    margin-left: 5%;
  }

  .BoxInfo_box-number__ALgdN {
    float: right;
  }

  .BoxInfo_box-data__3TAQ4 {
    width: 100%;
    padding: 0% 7% 0% 7%;
  }

  .BoxInfo_icon-number-wrapper__2AtNG {
    width: 50%;
  }

  .BoxInfo_image__1VN_e {
    border-radius: 0;
    width: 100%;
    padding: 0;
    margin-top: 1%;
  }
}

.DeleteBoxModalContent_content-wrapper__37Dqd {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.DeleteBoxModalContent_title__1D7PF {
  font-weight: 700;
  font-size: 18px;
}

.DeleteBoxModalContent_buttons__2-oDG {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 90px;
}

@media screen and (max-width: 768px) {
  .DeleteBoxModalContent_content-wrapper__37Dqd {
    height: 180px;
  }
  .DeleteBoxModalContent_buttons__2-oDG {
    width: 90%;
    align-self: center;
    flex-direction: column;
  }

  .DeleteBoxModalContent_delete-entire-box-button__3IT2U {
    margin-top: 10px;
  }
}

.MarkerHistoryElement_wrapper__2j011 {
  display: flex;
  padding: 10px 0;
}

.MarkerHistoryElement_text-wrapper__16bBm {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
}

.MarkerHistoryElement_box-location__3GZT9 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Footer_footer__1w0lV {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 0 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.5rem;
  height: 55px;
  z-index: 999;
}

.Footer_social-icon-text__3twMt {
  display: block;
  text-decoration: underline;
  margin-right: auto;
}

.Footer_social-icon__1sujD {
  width: 85%;
}

.Footer_social-icon-link__6yldP {
  margin-left: 17.5px;
}

@media screen and (max-width: 768px) {
  .Footer_footer__1w0lV {
    font-size: 1rem;
    justify-content: space-between;
    padding: 0 50px;
  }

  .Footer_social-icon-text__3twMt {
    display: none;
  }

  .Footer_social-icon__1sujD {
    width: 70%;
  }

  .Footer_social-icon-link__6yldP {
    margin: 0;
  }
}

.AdminProfile_flex-wrapper__RE2Wy {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* The linear gradient requires rgba colors here. */
  background: linear-gradient(rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.55)),
    url(/static/media/profile-background.570633e7.jpg);
  background-size: cover;
  background-position: center;
  padding: 5%;
}

.AdminProfile_profile-page-wrapper__33bFn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
}

.AdminProfile_top-buttons__1UZgF {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.AdminProfile_profile-form__JkCIg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  width: 100%;
  margin: 3vh 0;
  padding: 2.75em;
  border: 2px solid var(--color-light-gray);
  border-radius: 22px;
  z-index: 100;
}

.AdminProfile_logo-wrapper__fM_cb {
  max-width: 140px;
  z-index: 1000;
}

.AdminProfile_fya-logo__3W0mO {
  max-width: 100%;
  height: auto;
  width: auto;
  z-index: 1000;
}

.AdminProfile_form-input__2x-Mk {
  width: 100%;
  margin-top: 2.2vh;
  font-weight: bold;
}

.AdminProfile_editable-input__2c9uI {
  grid-gap: 0.65em;
  gap: 0.65em;
  display: flex;
  align-items: flex-end;
}

.AdminProfile_bottom-buttons__230OE {
  width: 100%;
  display: flex;
  padding-top: 0%;
  justify-content: flex-end;
  grid-gap: 2em;
  gap: 2em;
}

@media screen and (max-width: 768px) {
  .AdminProfile_profile-page-wrapper__33bFn {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    overflow-y: hidden;
  }

  .AdminProfile_background-image-container__2jVBX {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    flex: 1 1;
  }

  .AdminProfile_profile-form__JkCIg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 1.75em;
    background-color: transparent;
    border: 0px solid;
    border-radius: 0px;
    flex: 1 1;
    transform: translateY(-30%);
  }

  .AdminProfile_logo-container__1ARpT {
    max-width: 140px;
    transform: translateY(-50%);
  }

  .AdminProfile_fya-logo__3W0mO {
    max-width: 100%;
    height: auto;
    width: auto;
  }

  .AdminProfile_form-input__2x-Mk {
    width: 100%;
    margin-top: 2.2vh;
    font-weight: bold;
  }

  .AdminProfile_editable-input__2c9uI {
    grid-gap: 0.65em;
    gap: 0.65em;
    display: flex;
    align-items: flex-end;
  }

  .AdminProfile_bottom-buttons__230OE {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    padding-top: 8%;
    grid-gap: 1.75em;
    gap: 1.75em;
  }

  .AdminProfile_page-content-container__pQyW0 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto 0.15em;
  }
  .AdminProfile_flex-wrapper__RE2Wy {
    padding: 0%;
    background-image: none;
  }
}

.DeleteAccountModal_modal__138WP {
  display: flex;
  justify-content: center;
}

.DeleteAccountModal_delete-modal-content__1V3ki {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 36px;
}

.DeleteAccountModal_delete-modal-content__1V3ki .DeleteAccountModal_fya-logo__1-Uud {
  height: 125px;
  width: 125px;
  margin-bottom: 20px;
}

.DeleteAccountModal_delete-modal-content__1V3ki .DeleteAccountModal_step-content__1NMBU {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DeleteAccountModal_delete-modal-content__1V3ki .DeleteAccountModal_step-text__Dg1Dz {
  text-align: center;
  margin-bottom: 40px;
}

.DeleteAccountModal_delete-modal-content__1V3ki .DeleteAccountModal_step-button-group__1Vtgj {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .DeleteAccountModal_modal-heading__2fz1e {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .DeleteAccountModal_fya-logo__1-Uud {
    display: none;
  }

  .DeleteAccountModal_step-button-group__1Vtgj {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .DeleteAccountModal_delete-modal-content__1V3ki .DeleteAccountModal_step-button-group__1Vtgj {
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .DeleteAccountModal_delete-modal-content__1V3ki .DeleteAccountModal_step-text__Dg1Dz {
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    top: 60px;
    left: 20px;
  }

  .DeleteAccountModal_modal-content__3xV4v {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .DeleteAccountModal_modal-button-section__1LxDp {
    width: 80%;
    position: fixed;
    bottom: 75px;
  }

  .DeleteAccountModal_modal-button__18tJE {
    width: 100% !important;
  }

  .DeleteAccountModal_modal-header__33ELB {
    margin-top: 15px;
  }

  .DeleteAccountModal_check-icon__HECz7 {
    height: 175px;
    width: 175px;
  }
}

.SendLinkModal_modal-content__1eA7X {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 20px;
  text-align: center;
  padding: 20px;
}

.SendLinkModal_modal-one-input__3B6wz {
  margin: 0em 0 1em 0;
}

.SendLinkModal_error-message__1YuYe {
  color: var(--color-warning);
}

.SendLinkModal_modal-one-button__26Gou {
  align-self: flex-end;
  margin-top: 5%;
}

.SendLinkModal_checkmark__A4Pia {
  height: 125px;
  width: 125px;
  margin-bottom: 20px;
}

.SendLinkModal_modal-two-button__2rc04 {
  margin-top: 5%;
}

.SendLinkModal_input-wrapper__14r9B {
  width: 100%;
  text-align: left;
  padding-top: 5%;
}

.SendLinkModal_email-icon__AP11k,
.SendLinkModal_check-icon__2aAnE {
  margin: 0 auto;
  height: 125px;
  width: 125px;
  margin-bottom: 5%;
}

.SendLinkModal_send-link-modal-content__2Uxzc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.SendLinkModal_modal-body__3Gc8N {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .SendLinkModal_modal-content__1eA7X {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    padding: 25px;
  }

  .SendLinkModal_modal-button-section__36g7G {
    width: 80%;
    position: fixed;
    bottom: 75px;
  }

  .SendLinkModal_modal-one-button__26Gou {
    width: 100% !important;
  }

  .SendLinkModal_modal-two-button__2rc04 {
    width: 100% !important;
  }

  .SendLinkModal_modal-cancel-button__m1U1b {
    text-decoration: underline;
    color: var(--color-dark-blue);
    margin-top: 15px;
  }

  .SendLinkModal_input-wrapper__14r9B,
  .SendLinkModal_modal-header__2i2t1 {
    margin-top: 15px;
  }

  .SendLinkModal_email-icon__AP11k,
  .SendLinkModal_check-icon__2aAnE {
    height: 175px;
    width: 175px;
  }
}

.AdminProfilePage_page-wrapper__VtDZ- {
  width: 100%;
  height: 100%;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
}

.ExportCSVForm_csv-form-wrapper__3WTOh {
  flex-basis: 42%;
  width: 100%;
  margin: 1.4em 0em 1.4em;
}

.ExportCSVForm_export-csv-form__1ABBP {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.ExportCSVForm_section-wrapper__3tri8 {
  width: 400px !important;
}

.ExportCSVForm_filter-label-select__1989K {
  width: 400px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ExportCSVForm_input-drop-down__ErBcm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  grid-gap: 5px;
  gap: 5px;
}

.ExportCSVForm_organically-radio-group__JCmu2 {
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.ExportCSVForm_date-picker__1krUX {
  font-size: var(--chakra-fontSizes-md);
  height: 2.5rem;
  width: 200px;
  border: 1px solid rgb(226, 232, 240);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.ExportCSVForm_date-picker-error__3LIoo {
  font-size: var(--chakra-fontSizes-md);
  height: 2.5rem;
  width: 200px;
  border: 1px solid var(--color-warning);
  box-shadow: 0 0 0 1px var(--color-warning);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.ExportCSVForm_date-range__3Mr-R {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.ExportCSVForm_date-range-label__2qS2O {
  font-size: 14px;
  color: var(--color-gray);
}

.ExportCSVForm_filter-section-wrapper__3_Mmx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ExportCSVForm_filter-option-wrapper__2f_ph {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.ExportCSVForm_filter-choices__1aQ0T {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ExportCSVForm_box-detail-checkboxes__3WWed {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
}

.ExportCSVForm_box-detail-header__3_drC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ExportCSVForm_csv-form-labels__2nSdg {
  font-size: 20px;
  font-weight: 500;
}

.ExportCSVForm_error-message__1pgYG {
  color: red;
  font-size: 13px;
}

.ExportCSVForm_country-container__2Othl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  grid-gap: 5px;
  gap: 5px;
  border: 1px solid var(--color-light-gray);
  border-radius: 4.2pt;
}

.ExportCSVForm_country-box-options__2pYmP {
  padding: 0.45em 0em 0.45em 0.75em;
  margin-right: 0.6em;
}

@media screen and (max-width: 768px) {
  .ExportCSVForm_accordion-box__11yCh {
    border: 1px solid var(--color-light-gray);
    border-radius: 6.2pt;
  }

  .ExportCSVForm_accordion-items__1TBoz {
    align-items: left;
  }

  .ExportCSVForm_csv-form-wrapper__3WTOh {
    flex-basis: 42%;
    width: 100%;
    margin: 1.4em 0em 1.4em;
  }

  .ExportCSVForm_export-csv-form__1ABBP {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .ExportCSVForm_section-wrapper__3tri8 {
    width: 400px !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    grid-row-gap: 0.5em;
    row-gap: 0.5em;
  }

  .ExportCSVForm_filter-label-select__1989K {
    width: 400px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ExportCSVForm_input-drop-down__ErBcm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    grid-gap: 5px;
    gap: 5px;
  }

  .ExportCSVForm_organically-radio-group__JCmu2 {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .ExportCSVForm_date-picker__1krUX {
    font-size: var(--chakra-fontSizes-md);
    height: 2.5rem;
    width: 100%;
    border: 1px solid rgb(226, 232, 240);
    border-radius: 0.375rem;
    padding-left: 1rem;
  }

  .ExportCSVForm_date-picker-error__3LIoo {
    font-size: var(--chakra-fontSizes-md);
    height: 2.5rem;
    width: 100%;
    border: 1px solid var(--color-warning);
    box-shadow: 0 0 0 1px var(--color-warning);
    border-radius: 0.375rem;
    padding-left: 1rem;
  }

  .ExportCSVForm_date-range__3Mr-R {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .ExportCSVForm_date-range-label__2qS2O {
    font-size: 14px;
    color: var(--color-gray);
  }

  .ExportCSVForm_filter-section-wrapper__3_Mmx {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ExportCSVForm_filter-option-wrapper__2f_ph {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .ExportCSVForm_filter-choices__1aQ0T {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ExportCSVForm_box-detail-checkboxes__3WWed {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    align-items: left;
    grid-row-gap: 0.5em;
    row-gap: 0.5em;
  }

  .ExportCSVForm_box-detail-header__3_drC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ExportCSVForm_csv-form-labels__2nSdg {
    font-size: 20px;
    font-weight: 500;
  }

  .ExportCSVForm_error-message__1pgYG {
    color: red;
    font-size: 13px;
  }

  .ExportCSVForm_buttons-container__Bd0Vv {
    position: absolute;
    bottom: 30px;
    align-items: center;
    margin-left: 15%;
    width: 60%;
  }

  .ExportCSVForm_preview-csv-button__1Drkg {
    margin-left: 5%;
  }

  .ExportCSVForm_additional-input-header__1VHEK {
    text-align: center;
  }
}

.CommonAccordionSelector_csv-form-labels__1Tk5x {
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

@media screen and (max-width: 768px) {
  .CommonAccordionSelector_csv-form-labels__1Tk5x {
    font-size: 20px;
    font-weight: 500;
    display: flex;
  }

  .CommonAccordionSelector_header-container__LQ5T- {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .CommonAccordionSelector_header-selection-container__3K2Uw {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .CommonAccordionSelector_item-header__1hBIB {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.ExportCSV_export-csv-wrapper__2Oe5C {
  margin: 1em 2em;
  margin-bottom: 0;
}

.ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_export-csv-header__3sDqE {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;

  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--color-light-gray);
}

.ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_export-csv-header__3sDqE .ExportCSV_header-text__1FdQZ {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_export-csv-header__3sDqE .ExportCSV_header-text__1FdQZ {
  grid-gap: 1em;
  gap: 1em;
}

.ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_button-section__3CAPT {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.6em;
  gap: 0.6em;
}

.ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_button-section__3CAPT .ExportCSV_header-button__3UzpY {
  font-size: 18px !important;
}

.ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_export-csv-content__3NcWZ {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

@media screen and (max-width: 768px) {
  .ExportCSV_export-csv-wrapper__2Oe5C .ExportCSV_button-section__3CAPT {
    display: flex;
    justify-content: center;
    grid-gap: 0.6em;
    gap: 0.6em;
  }

  .ExportCSV_export-csv-wrapper__2Oe5C {
    margin: 1em 2em;
    max-height: 100vh;
  }
}

.CSVPreview_csv-preview-wrapper__1pfKg {
  border: 2px solid var(--color-light-blue);
  width: 100%;
  margin: 1em 2em 0em 0em;
  padding: 0 20px;
  height: calc(100vh - 352px);
}

.CSVPreview_scrollable-div__24nJV {
  overflow-y: overlay;
  max-height: 50%;
}

.CSVPreview_scrollable-div__24nJV::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.CSVPreview_scrollable-div__24nJV::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(78, 73, 73, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.CSVPreview_csv-preview__3fXEq {
  border-collapse: separate !important;
  width: 100%;
  border-spacing: 0px 20px;
}

.CSVPreview_csv-preview-header__2aZWM {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.CSVPreview_csv-preview-title__VifZW {
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: 600;
}

.CSVPreview_csv-preview__3fXEq td,
.CSVPreview_csv-preview__3fXEq th {
  text-align: left;
  max-width: 300px;
  position: relative;
  padding: 8px;
  height: 80px;
  background-color: var(--color-light-gray);
}

.CSVPreview_csv-preview__3fXEq td:last-child,
.CSVPreview_csv-preview__3fXEq th:last-child {
  grid-gap: 20px;
  gap: 20px;
  border-radius: 0 8px 8px 0;
}

.CSVPreview_csv-preview__3fXEq td:first-child,
.CSVPreview_csv-preview__3fXEq th:first-child {
  padding-left: 20px;
  border-radius: 8px 0 0 8px;
}

.CSVPreview_csv-preview__3fXEq th {
  font-weight: 600;
  border-bottom: none;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: 0;
  justify-content: center;
  white-space: nowrap;
  color: black;
  justify-content: center;
  white-space: nowrap;
}

.CSVPreview_csv-preview__3fXEq th:first-child {
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .CSVPreview_scrollable-div__24nJV {
    overflow-y: overlay;
    max-height: 65vh;
  }
}

.ExportCSVRow_green-check-icon__1uxGk {
  color: green !important;
  width: 13px;
  height: 10px;
}

.ExportCSVRow_red-cross-icon__293Px {
  color: red !important;
  width: 10px;
  height: 10px;
}

.ExportCSVRow_green-check-icon__1uxGk,
.ExportCSVRow_red-cross-icon__293Px {
  position: absolute;
  left: 80px;
  top: 40%;
}

.CommonConfirmationPage_confirmation-page__mvEjR {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}

.CommonConfirmationPage_logo__ojNKb {
  height: auto;
  margin-top: 12.5px;
  margin-left: 12.5px;
  align-self: flex-start;
  display: block;
}

.CommonConfirmationPage_logo-mobile__14WFX {
  width: 50px;
  height: auto;
  margin-top: 12.5px;
  margin-left: 12.5px;
  align-self: flex-start;
  display: none;
}

.CommonConfirmationPage_confirmation-content__3ikvk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.CommonConfirmationPage_confirmation-icon__26jeX {
  width: 125px !important;
  height: 125px !important;
  padding: 5px;
  color: var(--color-teal) !important;
  border: 7.5px solid var(--color-teal);
  border-radius: 50%;
}

.CommonConfirmationPage_confirmation-button__1mgBC {
  background-color: var(--color-teal) !important;
  color: var(--color-white) !important;
}

.CommonConfirmationPage_confirmation-message__2Ski2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 50px;
}

@media screen and (max-width: 768px) {
  .CommonConfirmationPage_logo__ojNKb {
    display: none;
  }

  .CommonConfirmationPage_logo-mobile__14WFX {
    display: block;
  }

  .CommonConfirmationPage_confirmation-title__95d8M {
    font-size: 28px !important;
  }

  .CommonConfirmationPage_confirmation-button__1mgBC {
    position: absolute !important;
    bottom: 60px;
    width: 80% !important;
  }
}

* {
  box-sizing: border-box;
}

.AddBoxForm_add-box-form__VwJka {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

/* textarea section */
.AddBoxForm_text-area__3a-DQ {
  resize: none;
}

/* launched organically section */
.AddBoxForm_box-launched-section___zsQr {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.AddBoxForm_box-launched-section___zsQr label {
  margin: 0;
}

.AddBoxForm_info-icon__2hvao {
  margin-bottom: 2px;
  position: relative;
}

/* tooltip */
.AddBoxForm_info-icon__2hvao:hover .AddBoxForm_tool-tip-text__1-K4A {
  visibility: visible;
}

.AddBoxForm_info-icon__2hvao .AddBoxForm_tool-tip-text__1-K4A {
  visibility: hidden;
  /* tooltip styling */
  width: 200px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 5px;
  /* position the tooltip */
  font-size: 0.7rem;
  box-shadow: 0px 6px 10px var(--color-black);
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -105px;
}

.AddBoxForm_info-icon__2hvao .AddBoxForm_tool-tip-text__1-K4A::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--color-black) transparent;
}

.AddBoxForm_info-icon__2hvao {
  margin-left: 2%;
}

.AddBoxForm_submit-button__2qWXb {
  float: right;
  margin: 5% 0;
}

.AddBoxForm_required-field__V1SCl::after {
  content: ' *';
  color: var(--color-warning);
}

/* date picker */
.AddBoxForm_date-picker__2GACm {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.AddBoxForm_date-picker-error__3iAc5 {
  border: 2.5px solid var(--color-warning);
}

.AddBoxForm_box-image__3q92l img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 5px;
}

.AddBoxForm_add-box-photo-preview-section__EL0D_ {
  height: 50%;
}

.AddBoxForm_add-box-photo-preview-section-hidden__3QnlY {
  display: none;
}

.AddBoxForm_add-box-info-section-left__1IGF-,
.AddBoxForm_add-box-info-section-right__2w7rb {
  width: 40%;
}

.AddBoxForm_box-launch-label__2jCtb {
  display: flex;
  width: 50%;
}

.AddBoxForm_launch-radio__2sW0A label {
  margin-right: 80%;
}

.AddBoxForm_additional-comments-sub-label__1vqk9 {
  color: var(--text-gray-color);
}

@media screen and (max-width: 768px) {
  .AddBoxForm_add-box-form__VwJka {
    flex-direction: column;
    align-items: center;
  }

  .AddBoxForm_box-launched-section___zsQr {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .AddBoxForm_add-box-info-section-left__1IGF-,
  .AddBoxForm_add-box-info-section-right__2w7rb {
    width: 80%;
  }

  .AddBoxForm_launch-radio__2sW0A label {
    margin-right: 100%;
  }

  .AddBoxForm_box-launch-label__2jCtb {
    width: 100%;
  }

  .AddBoxForm_attach-box-photo-label__154GJ {
    margin-left: 10%;
  }

  .AddBoxForm_submit-button__2qWXb {
    margin-top: 0%;
    margin-bottom: 8%;
    width: 100% !important;
  }

  .AddBoxForm_dropzone__OG0OK {
    width: 80%;
    margin: auto;
  }

  .AddBoxForm_launch-org-message__3Lgug {
    margin-left: 10%;
  }
}

.dropzone-zone {
  padding: 55px;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  height: 250px;
  border-color: var(--color-light-gray);
  background-color: var(--color-off-white);
  color: var(--color-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-accept {
  border-color: var(--color-success);
}

.dropzone-reject {
  border-color: var(--color-warning);
}

.dropzone-text {
  color: var(--color-black);
}

.dropzone-support-text {
  font-size: 0.8rem;
  text-align: center;
}

.dropzone-icon {
  margin: auto;
  font-size: 30px;
  color: var(--color-black);
}

.fileSelector {
  border: none;
  background-color: transparent;
  color: var(--color-black);
  text-decoration: underline;
  font-size: 1rem;
  cursor: pointer;
}

.files-list {
  list-style-type: none;
  padding: 0;
}

.remove-uploaded-photo-button {
  color: var(--color-gray) !important;
}

.file-item-span {
  color: var(--color-gray);
}

.AddBoxForm_add-box-page-title__Ap6kB {
  font-size: 2rem;
  font-weight: 500;
  margin: 30px 0 0 50px;
}

.AuthPageLayout_page-container__dNN80 {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  background-color: var(--color-teal);
}

.AuthPageLayout_auth-form__36f-0 {
  flex: 1 1;
  background-color: white;
}

.AuthPageLayout_auth-container__1Db1X {
  height: 100%;
  padding: 1% 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.AuthPageLayout_banner__zeDYo {
  flex: 1 1;
  background-size: cover;
  background-position: center;
}

.AuthPageLayout_logo-container__1__Pq {
  align-self: flex-start;
}

.AuthPageLayout_auth-form-component__3VnDM {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AuthPageLayout_logo-mobile__1_UGA {
  width: 50px;
  height: auto;
  display: none;
}

.AuthPageLayout_logo-container-mobile__3bmLr {
  margin-top: 12.5px;
  margin-left: 12.5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

@media screen and (max-width: 768px) {
  .AuthPageLayout_page-container__dNN80 {
    display: flex;
    flex-direction: column-reverse;
  }

  .AuthPageLayout_auth-form-component__3VnDM {
    width: 90%;
    margin: 20px 0px;
  }

  .AuthPageLayout_logo-container__1__Pq,
  .AuthPageLayout_logo__332Ek {
    display: none;
  }

  .AuthPageLayout_logo-mobile__1_UGA {
    display: block;
  }

  .AuthPageLayout_auth-form__36f-0 {
    flex: 0 1;
  }
}

.ResetPasswordForm_reset-password-form-container__3eodQ {
  width: 100%;
}

.ResetPasswordForm_reset-password-form__29eoe {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.ResetPasswordForm_reset-password-button__33NR5 {
  align-self: flex-end;
}

.ResetPasswordForm_form-heading__2G7uV {
  padding-bottom: 5%;
}

.ResetPasswordForm_reset-password-wrapper__ZYkRs {
  display: flex;
  justify-content: left;
}

.ResetPasswordForm_action-panel__2P0tq {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.ResetPasswordForm_return-to-login-link__3L0yG {
  color: var(--color-hyperlink);
  text-decoration: underline;
  margin-top: 5%;
  align-self: center;
  display: none;
}

.ResetPasswordForm_return-to-login-button__3wKkj {
  align-self: flex-start;
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-teal);
  box-sizing: border-box;
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  .ResetPasswordForm_return-to-login-button__3wKkj {
    display: none !important;
  }

  .ResetPasswordForm_return-to-login-link__3L0yG {
    display: block;
  }

  .ResetPasswordForm_reset-password-button__33NR5 {
    width: 100% !important;
  }
}

.Input_password-show-hide-button__1kVua {
  background-color: var(--color-white) !important;
  border: none;
  outline: none;
}

.Input_form-control__19Eij {
  padding: 0% 0%;
}

.Input_form-title__1uqud {
  font-weight: bold !important;
}

.Input_input-tools__Y3sM5 {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1% 0%;
}

.Input_form-error-wrapper__33JBp,
.Input_forgot-password-wrapper__10_Se {
  flex: 1 1;
}

.Input_form-error-wrapper__33JBp {
  margin: 0;
  padding: 0;
}

.Input_forgot-password-wrapper__10_Se {
  display: flex;
  justify-content: right;
}

.Input_forgot-password__3AuV- {
  color: var(--color-hyperlink);
}
.Input_form-error-message__1RMMI {
  margin-top: 0 !important;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .Input_forgot-password-wrapper__10_Se {
    display: none;
  }
}

.RegisterForm_register-form-container__xPEVC {
  width: 100%;
  margin-top: 5%;
}

.RegisterForm_register-form__2WXeT {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.RegisterForm_register-button__1m4t5 {
  width: 100% !important;
  align-self: flex-end;
}

.RegisterForm_form-heading__3a9Ni {
  padding-bottom: 5%;
}

.date-picker {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.date-picker-error {
  border: 2.2px solid var(--color-warning);
}

:root {
  --text-gray-color: var(--color-gray);
}

* {
  box-sizing: border-box;
}

.PickupBoxForm_pickup-box-form__1gVU8 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

.PickupBoxForm_required-field__1w806::after {
  content: ' *';
  color: var(--color-warning);
}

/* box image section */
.PickupBoxForm_box-image__2zHcv {
  width: 100%;
  height: 100%;
}

.PickupBoxForm_box-image__2zHcv img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 10px;
}

.PickupBoxForm_box-bottom__3Wn4C {
  margin-bottom: 5%;
}

.PickupBoxForm_box-bottom__3Wn4C small {
  color: var(--color-gray);
  color: var(--text-gray-color);
}

.PickupBoxForm_submit-button__2tDFm {
  float: right;
  margin-top: 3%;
}

.PickupBoxForm_pickup-box-photo-preview-section__2pWEi {
  height: 50%;
}

.PickupBoxForm_pickup-box-photo-preview-section-hidden__1X-yR {
  display: none;
}

.PickupBoxForm_verification-sub-label__39O_s {
  margin-bottom: 12px;
  color: var(--color-gray);
  color: var(--text-gray-color);
  font-style: italic;
  font-size: 14px;
}

.PickupBoxForm_pickup-box-info-section-left__3t1Nc,
.PickupBoxForm_pickup-box-info-section-right__ecy63 {
  width: 40%;
}

.PickupBoxForm_privacy-statement__14sy0 {
  margin-left: 3%;
  padding-bottom: 5%;
  font-size: 14px;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .PickupBoxForm_pickup-box-form__1gVU8 {
    flex-direction: column;
    align-items: center;
  }

  .PickupBoxForm_pickup-box-info-section-left__3t1Nc,
  .PickupBoxForm_pickup-box-info-section-right__ecy63 {
    width: 80%;
  }

  .PickupBoxForm_submit-button__2tDFm {
    width: 100% !important;
  }
}

.PickupBoxFormPage_pickup-box-form-title__19MWh {
  font-size: 2rem;
  font-weight: 500;
  margin: 30px 0 0 50px;
}

.BoxFormConfirmation_box-form-confirmation-page__1atgL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.BoxFormConfirmation_confirmation-text__HDbdL,
.BoxFormConfirmation_confirmation-message__3fE6A {
  margin-top: 3%;
  text-align: center;
  width: 60%;
}

.BoxFormConfirmation_check-icon__qkb-y {
  margin: 0 auto;
  height: 125px;
  width: 125px;
}

.BoxFormConfirmation_confirmation-check-circle__15ViW {
  font-size: 125px;
}

.BoxFormConfirmation_confirmation-button__3xQDX {
  margin-top: 5%;
}

@media screen and (max-width: 768px) {
  .BoxFormConfirmation_confirmation-text__HDbdL {
    margin-top: 10%;
  }

  .BoxFormConfirmation_confirmation-message__3fE6A {
    margin-left: 10%;
    margin-right: 10%;
  }

  .BoxFormConfirmation_confirmation-button__3xQDX {
    position: absolute !important;
    bottom: 60px;
    width: 80% !important;
  }
}

:root {
  --text-gray-color: var(--color-gray);
}

* {
  box-sizing: border-box;
}

.RelocateBoxForm_relocate-box-form__37mer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

.RelocateBoxForm_required-field__277B3::after {
  content: ' *';
  color: var(--color-warning);
}

/* box image section */
.RelocateBoxForm_box-image__xLYuW {
  width: 100%;
  height: 100%;
}

.RelocateBoxForm_box-image__xLYuW img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 10px;
}

.RelocateBoxForm_box-bottom__9MKFd {
  margin-bottom: 5%;
}

.RelocateBoxForm_box-bottom__9MKFd small {
  color: var(--color-gray);
  color: var(--text-gray-color);
}

.RelocateBoxForm_submit-button__3gf2F {
  float: right;
  margin-top: 3%;
}

.RelocateBoxForm_relocate-box-photo-preview-section__Lo-nV {
  height: 50%;
}

.RelocateBoxForm_verification-sub-label__3WKpm {
  margin-bottom: 12px;
  color: var(--color-gray);
  color: var(--text-gray-color);
  font-style: italic;
  font-size: 14px;
}

.RelocateBoxForm_relocate-box-photo-preview-section-hidden__VUJAh {
  display: none;
}

.RelocateBoxForm_relocate-box-info-section-left__17d0U,
.RelocateBoxForm_relocate-box-info-section-right__N4RcX {
  width: 40%;
}

.RelocateBoxForm_privacy-statement__3nhH3 {
  margin-left: 3%;
  padding-bottom: 5%;
  font-size: 14px;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .RelocateBoxForm_relocate-box-form__37mer {
    flex-direction: column;
    align-items: center;
  }

  .RelocateBoxForm_relocate-box-info-section-left__17d0U,
  .RelocateBoxForm_relocate-box-info-section-right__N4RcX {
    width: 80%;
  }

  .RelocateBoxForm_submit-button__3gf2F {
    width: 100% !important;
  }
}

.RelocateBoxFormPage_relocate-page-title__3XyAq {
  font-size: 2rem;
  font-weight: 500;
  margin: 30px 0 0 50px;
}

.CSVViewTable_scrollable-div__3ufoc {
  overflow-y: overlay;
  max-height: 50%;
}

.CSVViewTable_scrollable-div__3ufoc::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.CSVViewTable_scrollable-div__3ufoc::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(78, 73, 73, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.CSVViewTable_csv-table-container__1jpWM {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 260px);
}

.CSVViewTable_csv-table__o7pbc {
  border-collapse: separate !important;
  width: 100%;
  border-spacing: 0px 20px;
}

.CSVViewTable_csv-table__o7pbc th,
.CSVViewTable_csv-table__o7pbc td {
  text-align: left;
  padding: 0 8px;
  width: 300px;
}

.CSVViewTable_csv-table__o7pbc td {
  position: relative;
  padding: 8px;
  height: 80px;
  background-color: var(--color-off-white);
}

.CSVViewTable_csv-table__o7pbc td:last-child {
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  grid-gap: 20px;
  gap: 20px;
  border-radius: 0 8px 8px 0;
}

.CSVViewTable_csv-table__o7pbc td:first-child {
  padding-left: 20px;
  border-radius: 8px 0 0 8px;
}

.CSVViewTable_csv-table__o7pbc th {
  background-color: none;
  font-weight: 700;
  border-bottom: none;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: 0;
}

.CSVViewTable_csv-table__o7pbc th:first-child {
  padding-left: 20px;
}

.CSVViewTable_csv-table__o7pbc * {
  font-size: 1rem;
}

.CSVViewTable_show-pages-select__fzjJ9 {
  margin-right: 20px;
}

.CSVViewTable_csv-table-container-mobile__3fo4f {
  min-height: 65vh;
}

.DeleteBoxModal_delete-modal-container__1Zp9Y {
  margin: 5px 0px;
}

.DeleteBoxModal_delete-modal-text__19_tJ {
  margin: 0px 0px 20px 0px;
}

.ReadOnlyRow_edit-icon__eCeSG {
  color: var(--color-gray) !important;
  width: 15px;
  height: 15px;
}

.ReadOnlyRow_delete-icon__jP6u7 {
  color: var(--color-gray) !important;
  width: 20px;
  height: 20px;
}

.ReadOnlyRow_green-check-icon__2y942 {
  color: var(--color-success) !important;
  width: 13px;
  height: 10px;
}

.ReadOnlyRow_red-cross-icon__3dlZR {
  color: var(--color-warning) !important;
  width: 10px;
  height: 10px;
}

.ReadOnlyRow_green-check-icon__2y942,
.ReadOnlyRow_red-cross-icon__3dlZR {
  position: absolute;
  left: 70px;
  top: 40%;
}

.ReadOnlyRow_csv-error__cHABW td {
  border-top: 1px solid var(--color-warning);
  border-bottom: 1px solid var(--color-warning);
}

.ReadOnlyRow_csv-error__cHABW td:last-child {
  border-right: 1px solid var(--color-warning);
}

.ReadOnlyRow_csv-error__cHABW td:first-child {
  border-left: 1px solid var(--color-warning);
}

.ReadOnlyRow_input-right-warning__2fWzL {
  color: var(--color-warning);
  position: absolute;
  top: -3px;
  right: 25px;
}

.ReadOnlyRow_date-picker__1VNz3 {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.ReadOnlyRow_date-picker-error__3ez8n {
  border: 2.2px solid var(--color-warning);
}

.EditableRow_check-box__2RmLj {
  background-color: var(--color-white);
  transform: scale(1.2);
  position: absolute;
  left: 30px;
}

.EditableRow_edit-row__32DHN input {
  width: 200px;
  height: 35px;
  background-color: var(--color-white);
}

.EditableRow_edit-row__32DHN .EditableRow_input-right-warning__3N5yo {
  color: var(--color-warning);
  position: absolute;
  top: -3px;
  right: 25px;
}

.EditableRow_check-icon__w1q-Z {
  color: var(--color-gray);
  width: 20px;
  height: 15px;
}

.EditableRow_date-picker__3gA0n {
  font-size: 15px;
  height: 2.5rem;
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 0.375rem;
  padding-left: 1rem;
}

.EditableRow_date-picker-error__1CyAL {
  border: 2.2px solid var(--color-warning);
}

.EditableRow_csv-error__3g97Y td {
  border-top: 1px solid var(--color-warning);
  border-bottom: 1px solid var(--color-warning);
}

.EditableRow_csv-error__3g97Y td:last-child {
  border-right: 1px solid var(--color-warning);
}

.EditableRow_csv-error__3g97Y td:first-child {
  border-left: 1px solid var(--color-warning);
}

.LoginForm_login-form-container__3JeSl {
  width: 100%;
}

.LoginForm_login-form__2toNC {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.LoginForm_login-button__1wORK {
  width: 100% !important;
  align-self: flex-end;
  margin-top: 5%;
}

.LoginForm_form-heading__3qs69 {
  padding-bottom: 5%;
}

.LoginForm_forgot-password__3v_mj {
  color: var(--color-hyperlink);
  text-decoration: underline;
  margin-top: 5%;
  align-self: center;
  display: none;
}

@media screen and (max-width: 768px) {
  .LoginForm_forgot-password__3v_mj {
    display: block;
  }

  .LoginForm_login-button__1wORK {
    margin-top: 0;
  }
}

.ForgotPasswordForm_forgot-password-form-container__PW7P6 {
  width: 100%;
}

.ForgotPasswordForm_forgot-password-form__3g1pd {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.ForgotPasswordForm_send-email-button__-08wU {
  align-self: flex-end;
}

.ForgotPasswordForm_action-panel__14bO5 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ForgotPasswordForm_return-to-login-link__2Da8G {
  color: var(--color-hyperlink);
  text-decoration: underline;
  margin-top: 5%;
  align-self: center;
  display: none;
}

.ForgotPasswordForm_return-to-login-button__2uFhz {
  align-self: flex-start;
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-teal);
  box-sizing: border-box;
  border-radius: 6px;
}

.ForgotPasswordForm_form-heading__2StYZ {
  padding-bottom: 5%;
}

.ForgotPasswordForm_info-text__6jodc {
  padding-bottom: 5%;
}

@media screen and (max-width: 768px) {
  .ForgotPasswordForm_return-to-login-button__2uFhz {
    display: none !important;
  }

  .ForgotPasswordForm_return-to-login-link__2Da8G {
    display: block;
  }

  .ForgotPasswordForm_send-email-button__-08wU {
    width: 100% !important;
  }
}

.AboutPage_about-page__DDjIE {
  padding: 75px 100px;
  font-size: 1.25rem;
}

.AboutPage_about-heading__2NWCg {
  font-weight: 700;
  text-decoration: underline;
}

.AboutPage_about-title-heading__PGTrj {
  font-weight: 700;
}

.AboutPage_welcome-graphics-container-mobile__gr6bV {
  display: none;
}

.AboutPage_welcome-graphics-container__2NMKY {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 75px 100px 75px 0px;
}

.AboutPage_welcome-graphics-row__3QDkG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 75%;
}

.AboutPage_welcome-graphics-icon__K1Qfj {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AboutPage_welcome-graphics-text__2EMyA {
  margin-top: 30px;
  width: 20%;
  text-align: center;
}

.AboutPage_site-description-section__1sZfR {
  padding: 100px 0;
}

.AboutPage_tab-heading__1q1-a {
  padding: 0 5vw;
}

.AboutPage_launch-box-about-text-center__3SXAc {
  font-style: italic;
}

.AboutPage_privacy-note-section__17lkv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--color-lightest-blue);
  border-radius: 15px;
  padding: 35px 35px;
  margin-top: 75px;
}

.AboutPage_privacy-note-text__2FjZP {
  overflow-wrap: break-word;
}

.AboutPage_mobile-line-break__1ozbm {
  display: none;
}

.AboutPage_newline-mobile__2dLmE {
  display: none;
}

.AboutPage_hyperlink__1f1og {
  color: var(--color-hyperlink);
}

.AboutPage_tabs__2rwow {
  color: var(--color-gray);
}

.AboutPage_user-guide-section__wEfyM {
  display: flex;
  justify-content: space-between;
}

.AboutPage_launch-box-section__197JH,
.AboutPage_found-box-section__20wov {
  width: 45%;
}

.AboutPage_waves-icon__Vj2bK {
  margin-left: -45px;
  width: 300px;
}

@media screen and (max-width: 768px) {
  .AboutPage_about-page__DDjIE {
    padding: 25px;
    font-size: 1.15rem;
  }

  .AboutPage_about-heading__2NWCg {
    font-size: 1.25rem !important;
  }

  .AboutPage_about-text__2E6Or {
    margin-top: 20px;
  }

  .AboutPage_about-text-center__10UFa {
    padding: 35px 0;
  }

  .AboutPage_welcome-graphics-container__2NMKY {
    display: none;
  }

  .AboutPage_welcome-graphics-container-mobile__gr6bV {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 10px;
    margin-bottom: 35px;
  }

  .AboutPage_welcome-graphics-row__3QDkG {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 35px;
  }

  .AboutPage_welcome-graphics-icon__K1Qfj {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .AboutPage_welcome-graphics-text__2EMyA {
    margin-top: 0;
    margin-left: 25px;
    width: 100%;
    text-align: left;
  }

  .AboutPage_site-description-section__1sZfR {
    padding: 50px 0;
  }

  .AboutPage_mobile-line-break__1ozbm {
    display: block;
  }

  .AboutPage_newline-mobile__2dLmE {
    display: block;
  }

  .AboutPage_user-guide-section__wEfyM {
    display: flex;
    flex-direction: column;
  }

  .AboutPage_launch-box-section__197JH,
  .AboutPage_found-box-section__20wov {
    width: 100%;
    padding: 0;
  }

  .AboutPage_privacy-note-section__17lkv {
    margin: 0;
  }
}

.ErrorPage_error-page__1athW {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}

.ErrorPage_logo__3-joV {
  margin-top: 12.5px;
  margin-left: 12.5px;
  align-self: flex-start;
  position: absolute;
}

.ErrorPage_error-page-content__2Agx0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ErrorPage_error-message__1Jxzk {
  text-align: center;
  margin: 50px;
}

@media screen and (max-width: 768px) {
  .ErrorPage_home-button__qKYAX {
    position: absolute !important;
    bottom: 60px;
    width: 80% !important;
  }

  .ErrorPage_logo-mobile__3piU5 {
    width: 50px;
    margin-top: 12.5px;
    margin-left: 12.5px;
    align-self: flex-start;
    position: absolute;
  }
}

