.navbar {
  position: sticky;
  z-index: 900;
  background-color: var(--color-white);
}

.navbar-initials {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #3182ce;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.nav-link-unselected {
  text-decoration: none;
}

.nav-link {
  margin: auto;
  font-size: 1em !important;
  font-family: var(--chakra-fonts-body) !important;
  text-align: justify;
}

.nav-link:hover {
  text-decoration: none !important;
}

.nav-box {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 533.25px;
  flex-grow: 1;
  justify-content: right;
}
.nav-modal {
  border-radius: 0px !important;
}

.nav-link-container-mobile {
  margin-top: 25px;
}

.fya-logo {
  width: 49px;
}

@media screen and (max-width: 768px) {
  .nav-link {
    font-size: 1.5em !important;
  }
}
