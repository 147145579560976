.content-wrapper {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  font-weight: 700;
  font-size: 18px;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 90px;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    height: 180px;
  }
  .buttons {
    width: 90%;
    align-self: center;
    flex-direction: column;
  }

  .delete-entire-box-button {
    margin-top: 10px;
  }
}
